<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Fragmingam</span> </div>
        <div class="card-body">
            <div class="row mt-2">     
                    <div class="col-6">Edad: {{edad_u}}</div>
                    <div class="col-6">Ta Sistólica: {{ta_sistolica}}</div>
                    <div class="col-6">Col Total: {{col_total}}</div>
                    <div class="col-6">Col Hdl: {{col_hdl}}</div>
                    <div class="col-6">Tratamiento Hipertensión: {{tratamiento_hipertension}}</div>
                    <div class="col-6">Fuma Cigarrillos: {{fuma_cigarrillos}}</div>
                    <div class="col-6">Presencia Diabetes: {{presencia_diabetes}}</div>
                    <div class="col-6">Porcentaje: {{porcentaje}}</div>
                    <div class="col-6">Riesgo: {{riesgo}}</div>  
            </div>
        </div>
    </div>
</template>
<script>
import fragmingamService from '../../../../services/fragmingamService';
import {isEmpty} from "lodash";
export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    data() {
        return {
            
                genero:'',  
                edad_u:'',
                col_total:'',
                col_hdl:'',
                presencia_diabetes:'',
                ta_sistolica:'',
                tratamiento_hipertension:'',
                fuma_cigarrillos:'',
                porcentaje:'',
                riesgo:'',
                id_fragmingam:''
            
        }
    },
    async created(){
       
            const response=await fragmingamService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                    this.edad_u=response.data.edad;
                    this.col_total=response.data.col_total;
                    this.col_hdl=response.data.col_hdl;
                    this.presencia_diabetes=response.data.presencia_diabetes;
                    this.tratamiento_hipertension=response.data.tratamiento_hipertension;
                    this.ta_sistolica=response.data.ta_sistolica;
                    this.fuma_cigarrillos=response.data.fuma_cigarrillos;
                    this.porcentaje=response.data.porcentaje;
                    this.riesgo=response.data.riesgo;
                    this.id_fragmingam=response.data.id;
                } 
            
    },
}
</script>