<template>
<div>
   <div class="row mt-2">
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Género</label>
            <select class="form-control" v-model="genero">
                <option value="F">F</option>
                <option value="M">M</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Edad</label>
            <input type="number" class="form-control" v-model="edad_u">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">IMC</label>
            <input type="number" class="form-control" v-model="indice_masa_corporal">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Usa medicamentos para la presión</label>
            <select class="form-control" v-model="medicamentos_presion">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Antecedentes personales de diabetes</label>
            <select class="form-control" v-model="historial_glucosa">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Actividad fisica en horas a la semana</label>
            <input type="number" class="form-control" v-model="actividad_fisica">
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Consumo diario de frutas</label>
            <select class="form-control" v-model="consumo_diario_frutas">
                <option value="y">SI</option>
                <option value="n">NO</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Antecedentes familiares de diabetes</label>
            <select class="form-control" v-model="historial_diabetes">
                <option value="n">No</option>
                <option value="1g">1 Grado</option>
                <option value="2g">2 Grado</option>
            </select>
        </div>
        <div class="form-group col-lg-4 col-md-12 col-sm-12">
            <label class="form-label">Perimetro abdominal</label>
            <input type="number" step="any" class="form-control" v-model="perimetro_abdominal">
        </div>

        
        
   </div>
   <div class="row">
       <div class="col-6">
           Puntaje : {{this.puntaje}}
       </div>
        <div class="col-6">
           Riesgo : {{this.riesgo}}
       </div>
   </div>
   <div class="mt-2">
       
        <button class="btn btn-primary mx-2 btn-sm" @click="calcular();">Calcular</button>
    
    
        <button class="btn btn-success btn-sm" @click="guardar();">Guardar</button>
       
   </div>
</div>

</template>
<script>
import examenFisicoService from '../../../../services/examenFisicoService';
import findriscService from '../../../../services/findriscService';
import {findrisc} from '../../../../utils/utils';
import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import dayjs from 'dayjs';


export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    data() {
        return {
            
                genero:'',
                imc:'',
                medicamentos_presion:'',
                historial_glucosa:'',
                actividad_fisica:'',
                consumo_diario_frutas:'',
                historial_diabetes:'',
                puntaje:'',
                riesgo:'',
                perimetro_abdominal:'',
                peso:'',
                talla:'',
                id_findrisc:''
            
        }
    },
    methods: {
        async calcular(){
            try {

                const gender = this.genero === 'F' ? 'female' : 'male';

                const riesgo = findrisc(gender,
                    this.edad_u,
                    this.indice_masa_corporal,
                    this.perimetro_abdominal,
                    this.medicamentos_presion,
                    this.historial_glucosa,
                    this.actividad_fisica,
                    this.consumo_diario_frutas,
                    this.historial_diabetes
                )

            this.riesgo = riesgo['risk'];
            this.puntaje = riesgo['points'];
    
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                 Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async guardar(){
            try {

                let findrisc_h={
                    id_historia: this.idHistoria,
                    genero:this.genero,
                    edad:this.edad_u,
                    imc:this.indice_masa_corporal,
                    perimetro_abdominal:this.perimetro_abdominal,
                    medicamentos_presion:this.medicamentos_presion,
                    historial_glucosa:this.historial_glucosa,
                    actividad_fisica:this.actividad_fisica,
                    consumo_diario_frutas:this.consumo_diario_frutas,
                    historial_diabetes:this.historial_diabetes,
                    riesgo:this.riesgo,
                    puntaje:this.puntaje,
                }

                this.LoaderSpinnerShow();

                if(this.id_findrisc!==''){
                    findrisc_h['id']=this.id_findrisc;
                    await findriscService.update(findrisc_h); 
                }else{
                    await findriscService.store(findrisc_h); 
                    const response=await findriscService.showByHc(this.idHistoria);
                    this.id_findrisc=response.data.id;
                }
                
                
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    async created(){
        this.genero=this.sexo;
        //this.findrisc.edad=this.edad;
        if(this.finalizada==='N'){
           const response=await examenFisicoService.showByHc(this.idHistoria);
             if(!isEmpty(response.data)){
                this.talla=response.data.talla;
                this.peso=response.data.peso;
                this.perimetro_abdominal=Number(response.data.perimetro_abdominal);
            }

            const response_findrisc=await findriscService.showByHc(this.idHistoria);
                if(!isEmpty(response_findrisc.data)){
                    //this.edad_u=response_findrisc.data.edad;
                    this.medicamentos_presion=response_findrisc.data.medicamentos_presion;
                    this.historial_glucosa=response_findrisc.data.historial_glucosa;
                    this.actividad_fisica=response_findrisc.data.actividad_fisica;
                    this.consumo_diario_frutas=response_findrisc.data.consumo_diario_frutas;
                    this.historial_diabetes=response_findrisc.data.historial_diabetes;
                    this.perimetro_abdominal=response_findrisc.data.perimetro_abdominal;
                    this.puntaje=response_findrisc.data.puntaje;
                    this.riesgo=response_findrisc.data.riesgo;
                    this.id_findrisc=response_findrisc.data.id;
                } 
            }
    },
    watch:{
        finalizada: async function (value){
            if(value==='N'){
                const response=await examenFisicoService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                    this.talla=response.data.talla;
                    this.peso=response.data.peso;
                    this.perimetro_abdominal=Number(response.data.perimetro_abdominal);
                }
            }
        }
    },
    computed : {
        indice_masa_corporal (){
            
            if(this.peso !== '' && this.talla !== ''){
                let imc = Number(this.peso) / (Math.pow(Number(this.talla/100), 2) );
                return imc.toFixed(2);
            }

            return '';
        },
        edad_u : function(){
            if(isEmpty(this.fecha_nacimiento)) return 0;
            return dayjs().diff(this.fecha_nacimiento, 'year');
        },
    }
}
</script>